.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn img {
  width: 48px;
  height: 48px;
}

.marker-btn .evse-count {
  font-weight: 600;
  color: #fff;
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
}