.sideDrawer {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 30%;
}

.sideDrawer > div {
  height: 100%;
}

.sideDrawer > div > div {
  position: relative;
  top: 0;
  bottom: 0;
  width: unset;
  overflow-y: hidden;
}

.sideDrawer > div > div > div.MuiToolbar-root {
  display: none;
}