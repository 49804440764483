.cluster-marker {
  position: relative;
  color: #fff;
  font-weight: 400;
  background: #00285ce1;
  background-clip: border-box;
  background-clip: padding-box;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px;
  border: 4px solid #00285c98;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.cluster-marker:after {
  content: "";
  position: absolute;
  z-index: -1;
  /* height: 40px;
  width: 40px; */
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  border-radius: 50%;
  border: 5px solid #00285c48;
}
