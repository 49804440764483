.bottomDrawer {
  z-index: 3;
  position: absolute;
  min-height: 20px;
  touch-action: "auto";
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
}

.dragBar {
  display: flex;
  justify-content: center;
  background-color: rgb(247, 242, 237);
  flex-wrap: nowrap;
  flex-direction: row;
}

.bottomDrawer > div > div {
  position: relative;
  overflow: hidden;
  left: 0;
  right: 0;
}
