h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.available {
  color: rgb(61, 220, 132);
}

.inUse {
  color: #037cbf;
}

.outOfService {
  color: rgb(139, 142, 148);
}

.singleEvse {
  border-bottom: 1px solid rgb(196, 194, 194);
  margin-bottom: 5px;
  display: flex;
  padding-bottom: 5px;
  justify-content: space-between;
}

.singleEvseDetails {
  font-size: 11px;
  display: flex;
  text-align: center;
  align-content: center;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 110px;
}

.singleEvseDetails p {
  white-space: nowrap;
}

.singleEvseConnectorIcons {
  display: flex;
  text-align: center;
  align-content: center;
  font-size: 10px;
  font-weight: bold;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.singleEvseConnectorIcons img {
  width: 25px;
  height: 25px;
}

.singleConnectorIcon {
  min-width: 60px;
}

.detialsandIconContainer {
  display: flex;
  flex-direction: row;
}

.singleEvseTariff {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}

.tariff {
  font-size: medium;
}

.additionalFeesText {
  font-size: x-small;
  color: #FF2EAD;
}

.perkWhtext {
  font-size: x-small;
}