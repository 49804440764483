.legend {
  background-color: rgb(255, 255, 255);
  position: relative;
  bottom: 45px;
  z-index: 1;
  padding: 5px;
  display: inline-flex;
  font-weight: 500;
  color: rgb(0, 0, 0);
  min-width: 250px;
}

.legend img {
  width: 20px;
  height: 20px;
  margin: auto;
}

.legend p {
  padding: 0;
  margin: auto;
  margin-right: 10px;
  font-size: 8px;
}
