.mapboxgl-ctrl-logo {
  display: none !important;
}

.mainContainer {
  background-color: rgb(255, 255, 255);
  height: 100%;
  position: relative;
  display: flex;
}

.map {
  background-color: rgb(233, 243, 243);
  flex: 1;
}

.errorMessage {
  text-align: center;
}

.legendDrawerOpen {
  margin-left: 31%;
}

.legendDrawerClosed {
  margin-left: 1%;
}

.mapDrawerOpen .mapboxgl-ctrl-top-left {
  left: 31% !important;
}

.mapDrawerOpen .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
}

.mapDrawerOpen .desktopFilter {
  left: 31% !important;
}
