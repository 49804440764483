p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.isMobile {
  border-radius: 0px 0px 0px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: stretch;
}

.isDesktop {
  border-radius: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.locationPanelHeader {
  padding: 5px 10px 3px 10px;
  background-color: rgb(247, 242, 237);
  display: flex;
  flex-direction: column;
}

#closeIcon {
  align-items: right;
  position: absolute;
  right: 0;
  cursor: pointer;
  padding: 5px;
  width: 20px;
}

#locationName {
  display: inline-flex;
  align-items: center;
}

#postCode {
  display: inline-flex;
  align-items: center;
}

.connectors {
  background-color: rgb(255, 255, 255);
  overflow: auto;
  padding: 5px 5px 5px 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: flex-start;
  align-items: stretch;
}

.isDesktop .connectors::-webkit-scrollbar {
  width: 8px;
}
.isMobile .connectors::-webkit-scrollbar {
  width: 6px;
}
.connectors::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 25px;

}
.connectors::-webkit-scrollbar-thumb {
  background-color: rgb(185, 185, 185);
  border-radius: 25px;
}

.connectorIcons {
  display: flex;
  padding: 5px 10px 2px 10px;
  background-color: rgb(247, 242, 237);
  flex-direction: row;
  text-align: center;
  align-content: center;
  font-size: x-small;
  justify-content: flex-start;
  gap: 10px;
}

.connectorIcons::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.connectorIcons::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
}
.connectorIcons::-webkit-scrollbar-thumb {
  background-color: rgb(185, 185, 185);
  border-radius: 25px;
}

h4 {
  font-weight: normal;
}

.connectorIcons img {
  width: 35px;
  height: 35px;
}

.lastUpdated {
  padding: 2px 10px 5px 10px;
  background-color: rgb(247, 242, 237);
  font-size: x-small;
  text-align: right;
  align-items: baseline;
}

