/* -----------> DESKTOP SPECIFIC <----------- */
.desktopFilter {
  display: flex;
  flex-direction: column;
  padding: 2px;
  position: relative;
  z-index: 30;
  margin: 8px 0 0 275px;
  float: left;
  max-width: 100px;
  cursor: pointer;
}

.desktopFilterDropdown {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.534);
  padding: 5px 5px 12px 12px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 300px;
  z-index: 1;
  right: 0;
  position: relative;
  margin-top: 0.5rem;
  border-radius: 8px;
}

.desktopFilterContent {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 15px;
}

/* title for each MUI component */
.formLabel {
  color: rgb(0, 0, 0) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

/* text within each MUI component - desktop specific */
.desktopFilterContent .formControlLabel .MuiFormControlLabel-label {
  font-size: 14px;
}

/* desktop scrollbar */
.desktopFilterContent::-webkit-scrollbar {
  height: 5px;
  width: 7px;
}
.desktopFilterContent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
}
.desktopFilterContent::-webkit-scrollbar-thumb {
  background-color: rgb(185, 185, 185);
  border-radius: 25px;
}

.desktopFilterDropdownActions {
  border-top-width: 1px;
  border-top-color: #e4e7eb;
  border-top-style: solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0.75rem 0.5rem 0.25rem 0.5rem;
}

.desktopFilterDropdownActions p {
  cursor: pointer;
  margin: 15px 0px 0px 0px;
}

/*  ----------> COMMON FOR BOTH <----------- */
.filterButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 6px 15px 6px 6px;
  border-radius: 5px;
  box-shadow: 3px 3px 10px rgba(124, 124, 124, 0.438);
  background-color: white;
  color: #000000c0;
  align-items: center;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.filterButton img {
  height: 18px;
  padding: 5px 10px 5px 5px;
}

.currentType {
  margin: 0px 0px 13px 0px;
}

.sliderBox {
  padding: 0px 10px 0px 0px;
  justify-content: center;
}

/* -----------> MOBILE SPECIFIC <----------- */

.mobileFilter {
  padding: 2px;
  position: relative;
  margin: 50px 0 0 9px;
  float: left;
  max-width: 100px;
}

.mobileFilterDropdown {
  position: fixed;
  display: flex;
  background-color: white;
  z-index: 20;
  flex-direction: column;
  right: 0;
}

@media all and (orientation:landscape) {
  .mobileFilterDropdown {
    overflow: auto;
    padding: 30px 10px 10px 10px;
    left:50%;
    bottom:0px;
    top:0px;
  }
}

@media all and (orientation:portrait) {
  .mobileFilterDropdown {
    padding: 5px 5px 12px 12px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.534);
    position: relative;
    margin-top: 0.5rem;
    border-radius: 8px;
    width:280px;
  }
}

.mobileFilterContent {
  display: flex;
  flex: auto;
  height: auto;
  overflow: auto;
  padding: 10px 10px 0px 5px;
}

@media all and (orientation: portrait) {
  .mobileFilterContent {
    flex-direction: column;
  }
}

@media all and (orientation: landscape) {
  .mobileFilterContent {
    flex-direction: column;
    /* justify-content: space-between; */
  }
  .mobileFilterContent .sliderBox {
    width: 260px;
  }
}

/* Size of the checkboxes svg icons - not actual font-size */
.MuiSvgIcon-root {
  font-size: 28px !important;
}

/* title for each MUI component - mobile specific */
.mobileFilterContent .formLabel {
  font-size: 18px !important;
}

/* text within each MUI component - mobile specific */
.mobileFilterContent .formControlLabel .MuiFormControlLabel-label {
  font-size: 16px;
}

.mobileFilterDropdownActions {
  border-top-width: 1px;
  border-top-color: #e4e7eb;
  border-top-style: solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0.75rem 0.5rem 0.25rem 0.5rem;
}

.mobileFilterDropdownActions p {
  margin: 6px 0px 0px 0px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

#doneButton {
  color: rgb(15, 24, 255);
  font-weight: 700;
}