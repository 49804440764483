.App {
  text-align: center;
}

#root {
  margin: 0px;
  padding: 0px;
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  flex: 1;
}

html {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

body {
  padding: 0;
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overscroll-behavior: contain; /* This disables automatic pull-down-to-refresh on mobile - Android */
  overflow: hidden; /* This disables automatic pull-down-to-refresh on mobile - iOS */
}

.container > .header {
  position: relative;
}

.header img {
  width: 100px;
  padding: 10px 0px 10px 0px;
  background-color: rgb(255, 255, 255);
}

.contents {
  flex: 1;
}

@media screen and (min-width: 100px) {
  html {
    font-size: calc(9px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 18px;
  }
}

/*
  Using viewport units and calc(), we can have font-size (and other properties) adjust their size based on the size of the screen. So rather than always being the same size, or jumping from one size to the next at media queries, the size can be fluid.

  Code:
  font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); 
  */
